<template>
  <div id="annuario" v-if="this.annuarioDB.length != 0">
    <div class="titre-principal centre" style="padding: 20px 0">
      <div :abcd="retourAnnuaire"></div>
      Annuario Penitenzieri
    </div>
    <div
      class="list-center" 
      v-for="reparto in this.reparti" 
        :key="reparto.id"
      >
        <router-link :to="'/annuario/' + reparto.page">
          <Card :style="{ backgroundColor : reparto.colortheme }">
            <template #content>
              <strong>{{ reparto.label.toUpperCase() }}</strong>
            </template>
          </Card>
        </router-link>
    </div>
    <div style="display: flex; flex-direction: column;">
      <div id="divTitreFeste" v-if="afficheFeste">
        <div @click="swapDiv('feste')" class="titre1 anchor">Onomastici / Compleanni</div>
      </div>
      <div id="divTitreAgenda" v-if="afficheAgenda">
        <div @click="swapDiv('agenda')" class="titre3-bis majuscule">Agenda</div>
      </div>
    </div>
    <div id="divListeFeste" v-if="afficheFeste && afficheListeFeste">
      <div v-if="this.fratiList">
        <div v-for="(frate, index) in this.fratiList" :key="index">
          <span v-html="frateElemento(frate)"></span>
        </div>
      </div>
    </div>
    <div v-if="this.$store.state.eventsListOk && afficheListeAgenda" id="divListeAgenda">
      <div v-html="this.$store.state.eventsList"></div>
    </div>
    <div v-if="!this.$store.state.eventsListOk">
      <img src="@/assets/img/wheel.gif">
    </div>
  </div>
</template>

<script>
var meseBreak = "";
var meseFirst = true;
var deuxfois = 0;
var nextYear = false;
var transYear = 0;

import Card from "primevue/card";
import axios from 'axios'
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "annuario-frati",
  components: { Card },
  data() {
    return {
      afficheAnnuario: false,
      afficheFeste: true,
      afficheListeFeste: false,
      afficheListeAgenda: true,
      afficheAgenda: false,
      reparti: null,
      fratiList: "",
      agendaList: "",
      today: null,
      currentYear: null,
      currentMonth: null,
      currentDay: null,
      mesi: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
      timer: '',
    } 
  },
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen', 'loginLevel']),
    retourAnnuaire() {
      console.log('AAAAAA ', this.annuarioDB)
      return false;
    }
  },
  created() {
    if ( !this.annuarioDBOpen ) {
      this.$store.dispatch("getAnnuario"); }
    else {
      console.log('annuario nel created = ', this.annuarioDB)
      this.reparti = this.annuarioDB[0];
      this.fratiList = this.membriComunita();
    }

    // Load Agenda
    if ( !this.$store.state.eventsListOk ) {
      this.fetchEventsList();
    }
  },
  watch: {
    annuarioDB: function() {
      this.reparti = this.annuarioDB[0];
      this.fratiList = this.membriComunita();
    }
  },
  mounted() {
    nextYear = false;
    this.today = new Date();
    this.currentYear = this.today.getFullYear();
    this.currentMonth = this.today.getMonth() + 1;
    this.currentDay = this.today.getDate();
    transYear = this.currentYear;
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
  methods: {
    fetchEventsList() {
      // recherche l'agenda des rendez-vous quotidiens

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      console.log(' urlparams.get("debut" ', urlParams.get("debut"))
      if (urlParams.has('debut')) var dateDebutVariable = urlParams.get('debut')
      else { 
        var dt = new Date();
        let dt_an = dt.getFullYear();
        let dt_mois = (dt.getMonth() + 1).toString(); if (dt_mois.length < 2 ) dt_mois = '0' + dt_mois ;
        let dt_jours = dt.getDate().toString(); if (dt_jours.length < 2 ) dt_jours = '0' + dt_jours ;
        dateDebutVariable = dt_an + "-" + dt_mois + "-" + dt_jours;
      }
      console.log('valeur de départ = ', dateDebutVariable);

      var calenders = Array();
      // Calendario degli Eventi del Collegio dei Penitenzieri
      calenders[0] = "0730dd5d185bd661b330b02b841ebf5e8e873f0c446f0b182515d8e539745ff0@group.calendar.google.com"; 
      var calendersJSON = JSON.stringify(calenders);
      console.log("calendersJSON = ", calendersJSON )

      console.log('login level = ', typeof localStorage.getItem('user-level') );
      axios.get( "https://www.cpv-ofmconv.org/php/getCalendar.php", { params: { periode: 99, cal: calendersJSON, debut: dateDebutVariable, level: localStorage.getItem('user-level') } })
        .then(response => {
          this.agendaList = response.data;
          this.afficheAgenda = true;
          this.$store.commit( 'SET_EVENTS_LIST', response.data )
          this.$store.commit( 'SET_EVENTS_LIST_OK', true )
          console.log('eventslist = ', response.data)
          console.log('eventlistok = ', this.$store.state.eventsListOk )
        }
      )
    },
    swapDiv( whichDiv ) {
      // ONOMASTICI / FESTE
      if ( whichDiv == 'feste') {
        if ( this.afficheListeFeste ) {    // if feste displayed, donc en 2e position alors on ne fait rien 
          console.log(''); }
        else {
          this.afficheListeFeste  = true;  // sinon on affiche  
          this.afficheListeAgenda = false;
          $( '#divTitreFeste' ).css( "order", '2' );
          $( '#divTitreAgenda' ).css( "order", '1' );
          $( '#divTitreFeste div' ).removeClass("titre1 anchor").addClass("titre3-bis majuscule"); 
          $( '#divTitreAgenda div' ).removeClass("titre3-bis majuscule").addClass("titre3 anchor"); 
        }
      }
      // AGENDA
      else {
        if ( this.afficheListeAgenda ) {    // if feste displayed, donc en 2e position alors on ne fait rien 
          console.log(''); }
        else {
          this.afficheListeAgenda  = true;  // sinon on affiche  
          this.afficheListeFeste = false;
          $( '#divTitreAgenda' ).css( "order", '2' );
          $( '#divTitreFeste' ).css( "order", '1' );
          $( '#divTitreAgenda div' ).removeClass("titre3 anchor").addClass("titre3-bis majuscule"); 
          $( '#divTitreFeste div' ).removeClass("titre3-bis majuscule").addClass("titre1 anchor"); 
        }
      }
    },

    duevolte() {
      return deuxfois++;
    },

    membriComunita() {
      var giorno = [];
      var x = 0;
      var cptMembro = 0;

      console.log( "store.state.annuariodb = ", this.annuarioDB[1] );

      // loop l'annuaire pour les frères [1] et pour les soeurs [2]


      var frateSuora = ['Fr', 'Sr'];
      for (let i = 1; i <= 2; i++) {
        for (let membro of this.$store.state.annuarioDB[i]) {
          console.log('db = ', this.$store.state.annuarioDB[i], ' i = ', i )
          console.log('membro', this.$store.state.annuarioDB[i])
          let theNascita = membro.nascita.split(".");
          let nascitaDate =
            theNascita[1] + "." + theNascita[0] + "." + theNascita[2];
          giorno[x] = [];
          giorno[x].push(nascitaDate, "nascita", cptMembro, membro.nome, frateSuora[i-1], membro.festa);
          x++;

          let theOnoma = membro.onomastico.split(".");
          let onomaDate =
            theOnoma[1] + "." + theOnoma[0] + "." + this.currentYear;
          giorno[x] = [];
          giorno[x].push(onomaDate, "onoma", cptMembro, membro.nome, frateSuora[i-1], membro.festa);
          x++;

          if (membro.professione != null) {
            let theProf = membro.professione.split(".");
            let profDate = theProf[1] + "." + theProf[0] + "." + theProf[2];
            giorno[x] = [];
            giorno[x].push(profDate, "prof", cptMembro, membro.nome, frateSuora[i-1], membro.festa);
            x++;
          }

          if (membro.ordinazione != null) {
            let theOrdi = membro.ordinazione.split(".");
            let ordiDate = theOrdi[1] + "." + theOrdi[0] + "." + theOrdi[2];
            giorno[x] = [];
            giorno[x].push(ordiDate, "ordi", cptMembro, membro.nome, frateSuora[i-1], membro.festa);
            x++;
          }

          cptMembro++;
        }
      }

      // sort by date
      giorno.sort(function (a, b) {
        if (a[0] === b[0]) {
          return 0;
        } else {
          return a[0] < b[0] ? -1 : 1;
        }
      });

      const cM = this.currentMonth;
      const cD = this.currentDay;

      // find the first day/month from the current day
      const found = giorno.findIndex(function (element) {
        const words = element[0].split(".");
        return (
          (parseInt(words[0]) > cM && parseInt(words[1]) <= cD) ||
          (parseInt(words[0]) == cM && parseInt(words[1]) >= cD)
        );
      });

      //      console.log("found = ", found);
      if (found > 0) {
        var newGiornoPart1 = giorno.slice(0, found);
        var newGiornoPart2 = giorno.slice(found);
        var newGiorno = newGiornoPart2.concat(newGiornoPart1);
      } else {
        newGiorno = giorno;
      }
//      console.log("newGiorno = ", newGiorno);

      return newGiorno;
    },

    frateElemento(frate) {
      var frateTemp = frate;
      var dataFesta = frateTemp[0].split(".");
      var meseFesta = this.mesi[parseInt(dataFesta[0]) - 1];
      var meseFestaDisplay = meseFesta.toUpperCase();
      var meseDisplay = "";
      if ( meseFirst ) {
        var classMese = 'titre2-bis';
        meseFirst = false; }
      else {
        classMese = 'titre2';
      }
      if (meseBreak != meseFesta) {
        meseDisplay = "<div class='" + classMese + "'>" + meseFestaDisplay + "</div>";
        meseBreak = meseFesta;
      }

      // var festaFrate = this.annuarioDB[1][frate[2]].festa;
      var festaFrate = frate[5];
      if (
        parseInt(frateTemp[0].substring(0, 2)) < this.currentMonth &&
        !nextYear
      ) {
        transYear++;
        nextYear = true;
      }
      var annoanniv = "";
      var anni = "";
      var fontRossa = "";
      var annicomputed = "";
      if (frateTemp[1] == "nascita") {
        var festaLabel = ": Compleanno di ";
        if (festaFrate == "compleanno") {
          fontRossa = " feste-rosse";
        }
      }
      // console.log('fontRossa ', fontRossa);
      if (frateTemp[1] == "onoma") {
        festaLabel = ": Onomastico di ";
        if (festaFrate == "onomastico") {
          fontRossa = " feste-rosse";
        }
      }
      if (frateTemp[1] == "prof") {
        festaLabel = ": Professione di ";
      }
      if (frateTemp[1] == "ordi") {
        festaLabel = ": Ordinazione di ";
      }
      if (frateTemp[1] != "onoma" && frateTemp[4] == 'Fr' ) {
        annoanniv = parseInt(frateTemp[0].substring(6));
        var compleannoAnni = transYear - annoanniv;
        annicomputed = " - " + annoanniv;
        anni = " (" + compleannoAnni + ")";
      }
      if ( frate[4] == 'Fr' ) { var libelleTitre = ' P. '; }
      else { libelleTitre = ' Sr '; }
      return (
        "<div class='align-left'>" +
        meseDisplay +
        "<div class='row" +
        fontRossa +
        "'><strong>" +
        dataFesta[1] +
        "</strong>" +
        festaLabel +
        libelleTitre +
        frate[3] +
        annicomputed +
        anni +
        "</div>" +
        "</div>"
      );
    },
  },
};
</script>

<style>
#annuario .p-card,
#annuario .p-card-body { border-radius: 10px !important; margin: 5px 10px; }
#annuario .p-card-body { padding: 0px !important; }
#annuario .p-card-content { color: white; font-size: 22px; text-align: center;   }
#annuario .list-center a { color: white; }
#annuario .afficheAgenda a { cursor: pointer; }

#annuario .month { 
  margin: 5px 10px 0 10px;
  font-weight: bold;
  font-size: 18px;
  color: white; 
  padding: 5px;
  background-color: #6c8cbf;
  border-radius: 5px;
}
#divTitreFeste  { order: 1 }
#divTitreAgenda { order: 2 }
</style>