import { createStore } from 'vuex'
import axios from 'axios'
import { useRoute } from 'vue-router';
import router from '../router'
import $ from 'jquery';

export default createStore({
  state: {
    annuarioDB: [],
    annuarioDBOpen: false,
    annuarioDBUpdate: {},
    altroDB: [],
    altroDBOpen: false,
    DB: {},
    DBOpen: false,
    loginSession: false,
    loginPass: "",
    loginActif: false,
    spinnerON: false,
    errorMessage: "",
    viewPDFUrl: "",
    eventsList: null,
    eventsListOk: false,
    host: "https://www.cpv-ofmconv.org/",
    routerParams: { 
      urlBack: '',
      libelleBack: '',
      noTab: 0
    },
    params: [],
    parameters: [],
    parametersOpen: false,
  },
  mutations: {
    SET_ACTIVE_TAB( state, val ) { state.activeTab = val; },
    SET_ANNUARIO_DB( state, val ) { state.annuarioDB = val; },
    SET_ANNUARIO_DB_OPEN( state, val ) { state.annuarioDBOpen = val; },
    SET_ANNUARIO_DB_UPDATE( state, val ) { state.annuarioDBUpdate = val; },
    SET_ANNUARIO_DB_UPDATE_MEMBER( state, val) {
      console.log('anndbupmem.state = ', state)
      console.log('anndbupmem.val = ', val)
      state.annuarioDB[val.reparto][val.member] = val.value;
      console.log("db après mise à jour ", state.annuarioDB)
    },
    SET_ALTRO_DB( state, val ) { state.altroDB = val; },
    SET_ALTRO_DB_OPEN( state, val ) { state.altroDBOpen = val; },
    SET_DB( state, val ) { state.DB = val; },
    SET_DB_OPEN( state, val ) { state.DBOpen = val; },
    SET_LOGIN_SESSION( state, val ) { state.loginSession = val; },
    SET_LOGIN_PASS( state, val ) { state.loginPass = val; },
    SET_LOGIN_LEVEL( state, val ) { state.loginLevel = val; },
    SET_LOGIN_ACTIF( state, val ) { state.loginActif = val; },
    SET_SPINNER_ON( state, val ) { state.spinnerON = val; },
    SET_ERROR_MESSAGE( state, val ) { state.errorMessage = val; },
    SET_VIEWPDF_URL( state, val ) { state.viewPDFUrl = val; },
    SET_ROUTER_PARAMS( state, val ) { state.routerParams = val; },
    SET_PARAMS( state, val ) { state.params = val; },
    SET_PARAMETERS( state, val ) { state.parameters = val; },
    SET_PARAMETERS_OPEN( state, val ) { state.parametersOpen = val; },

    SET_EVENTS_LIST( state, val ) { state.eventsList = val; },
    SET_EVENTS_LIST_OK( state, val ) { state.eventsListOk = val; },
  },

  actions: {

    getAnnuario({ commit }) {
      commit( 'SET_SPINNER_ON', true )
      commit( 'SET_ANNUARIO_DB_OPEN', false )
      console.log('getAnnuario action');
      axios.get( this.state.host + 'php/readDBAnnuario.php')
          .then(response => {
              console.log(" the annu ", response.data)
              var donnees = response.data[1];
              // Replace the ... of the orari in \r\n
              for (var i in donnees) {
                var ligne = donnees[i]
                ligne.orari = ligne.orari.replaceAll("...", "\r\n"); //remplace les trois points par retour chariot
                response.data[1][i].orari = ligne.orari 
                //result += nomObj + "." + i + " = " + obj[i] + "\n";
              } 
              commit( 'SET_ANNUARIO_DB',  response.data )
              commit( 'SET_ANNUARIO_DB_OPEN', true )
              console.log( ' getAnnuario mis à jour = ', this.state.annuarioDB )
              commit( 'SET_SPINNER_ON', false )
            })
    },

    getAltroCategoria({ commit }) {
      commit( 'SET_SPINNER_ON', true )
      commit( 'SET_ALTRO_DB_OPEN', false )
      commit( 'SET_ALTRO_DB', {} )
      console.log('getAltroCategoria action');
      console.log('id = ', useRoute().params.id );
      console.log('host = ', this.state.host );
      axios.get( this.state.host + 'php/readDBAltroCategoria.php', { params: { categoria: useRoute().params.id, host: this.state.host } })
          .then(response => {
              commit( 'SET_ALTRO_DB', response.data )
              commit( 'SET_ALTRO_DB_OPEN', true )
              commit( 'SET_SPINNER_ON', false )
              console.log( ' getAltroCategoria = ', response.data )
         })
    },
    loginVerify({ commit }) {
      commit( 'SET_SPINNER_ON', true )
      console.log('login action');
      commit( 'SET_LOGIN_LEVEL', 0 );
      axios.get( this.state.host + 'php/login.php', { params: { pass: this.state.loginPass } })
          .then(response => {
              var passLogin = response.data;
              console.log( ' response data = ', response.data );
              commit( 'SET_LOGIN_LEVEL', passLogin.level );
              console.log('level = ', passLogin.level)
              if ( passLogin.level > 0 ) {
                localStorage.setItem( 'user-level', passLogin.level );
                localStorage.setItem( 'user-password', this.state.loginPass );
                commit( 'SET_ERROR_MESSAGE', "" ); 
                console.log('push')
                router.push("/"); }
              else {
                commit( 'SET_ERROR_MESSAGE', passLogin.label );
              }
              commit( 'SET_SPINNER_ON', false );
            })
    },
    // Highlight the right tab
    setHighlight( { state }, val ) {
      console.log('state = ', state)
      const highlightedItems = document.querySelectorAll(".p-tabmenuitem");
      highlightedItems.forEach(function(userItem) {
        var routeActuelle = useRoute().path;
        var routeduLien = $( userItem  ).find('a').attr('href');
        var trouve = routeActuelle.search(routeduLien);
        var homeclick = (routeduLien == '/' && routeActuelle == '/') ? true : false; 
        if ( val.on && trouve > -1 && routeduLien !== '/' || homeclick ) {
          $( userItem ).addClass( "p-highlight" ); }
        else {
          $( userItem ).removeClass( "p-highlight" );
        }
      });
    },

    // Get Params
    getParams({ commit, state }) {
      commit( 'SET_SPINNER_ON', true )
      return axios.get( this.state.host + 'php/getParams.php')
          .then(response => {

            commit( 'SET_PARAMETERS', response.data )

            // Création du tableau des paramètres après l'extraction de la database
            var c = [];
            var the_params = [];
            Object.entries(state.parameters).forEach(entry => {
              const [key, value] = entry;
              console.log(key)
              Object.entries(value).forEach(entry2 => {
                const [key2, value2] = entry2;
                c[key2] = value2;
              });
              the_params[c['code']] = c['label'];
            });

            console.log('parametres sont chargés ', the_params)
            commit( 'SET_PARAMS', the_params )
            commit( 'SET_SPINNER_ON', false )
            commit( 'SET_PARAMETERS_OPEN', true )
      })
    },

    // Get Params
    updateDBAnnuario({ commit, state }) {
      commit( 'SET_SPINNER_ON', true )

      //state.annuarioDBUpdate = state.annuarioDB[1][state.routerParams.noTab];
      var stringParam = JSON.stringify( state.annuarioDBUpdate )
      console.log('stringParam = ', stringParam)
      return axios.post( this.state.host + 'php/updateDBAnnuario.php', { stringParam })
          .then(response => {

            commit( 'SET_SPINNER_ON', false )
            return response.data;
      })
    },
  }
})
