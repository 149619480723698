<template>
  <div id="collegio" v-if="annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/">Annuario</router-link>
    </div>
    <div class="titre-principal centre">
      Collegio dei Penitenzieri Vaticani
    </div>
    <div class="centre fs18 bold mb20">
      
    </div>

    <div class="flex flex-space-between flex-align-end">
      <div class="flex" style="margin-left: 5px;">
        <div class="flex flex-align-end">
          <router-link to="/annuario/orariogiornaliero" style="line-height: 0.8;">
            <img class="submenuIcon" src="@/assets/img/icon_agenda.png" v-tooltip.top="'Orario giornaliero'" width="100" />
          </router-link>
          <router-link 
            :to="{ name: 'viewer', 
                  params: { linkID: 'orario_basilica/999999 - Orario Settimanale Basilica.pdf' }}"
            style="line-height: 0.8;"    
          >
            <img class="submenuIcon" src="@/assets/img/icon_pdf.png" v-tooltip.top="'PDF dell\'orario'" width="100" />
          </router-link>
          <router-link to="/annuario/giubileo">
            <div class="submenu ml5">Aggiunti</div>
          </router-link>
        </div>
      </div>
      <div style="margin-right: 8px;">
        <label id="attivo"><span>Solo attivi&nbsp;</span>
          <input class="attivoBox" type="checkbox" style="vertical-align: middle;" 
              :checked="this.attivo"
              @click="this.attivo = !this.attivo">
        </label>
      </div>
    </div>

    <div
      class="list-center"
      v-for="(membro, index) in this.annuarioDB[1]"
      :key="membro.immagine"
    >
      <div class="list-center" v-if="(membro.tipo == 'ord' && this.attivo == true && membro.attivo == 1) || (membro.tipo == 'ord' && this.attivo == false )">
        <router-link
          :to="{
            name: 'SchedaFrate',
            params: { noFrate: index, id: membro.immagine },
          }"
          @click="memberClicked(index)" 
        >
          <Card>
            <template #content>
              <div class="flex flex-space-between">
                <div>
                  <strong>{{ membro.cognome.toUpperCase() }}</strong>
                  {{ membro.nome }}
                </div>
                <div v-if="this.editable" class="flex flex-direction-column flex-center">
                  <img height="25" @click.stop.prevent="editClick(index, membro.immagine)" src="@/assets/img/buttonEdit.png">
                </div>
              </div>
            </template>
          </Card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "collegio-penitenzieri",
  components: { Card },
  data() {
    return {
      annuarioDisplay: {},
      attivo: true,
      editable: false,
    };
  },
  computed: {
    ...mapState(["annuarioDB", "annuarioDBOpen", "annuarioDBPending", "routerParams"]),
  },
  created() {
    if (!this.annuarioDBOpen) {
      console.log("Annuario.vue mounted");
      this.$store.dispatch("getAnnuario");
    }
    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top of page

    // Set the user level
    var theUserLevel = localStorage.getItem('user-level');
    console.log('user-level = ', theUserLevel); 
    this.editable = theUserLevel == "100" ? true : false; 
  },
  mounted() {
    this.$store.dispatch("setHighlight", { on: true }); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch("setHighlight", { on: false }); // highlight the tab of tabMenu
  },
  methods: {
    memberClicked(memberIndex) {
      this.$schedaFrate = memberIndex;
      window.schedaFrate = memberIndex;

      console.log("index = ", this.$schedaFrate);
      console.log("window.index = ", window.schedaFrate);
    },

    editClick(numberTab, immagine) {
      console.log('edit cliqué');
      this.lesParametres = { 
        urlBack: '/annuario/collegio',
        libelleBack: 'Collegio dei Penitenzieri Vaticani',
        noTab: numberTab
      }
      console.log('lesParametres = ', this.lesParametres)
      this.$store.commit( 'SET_ROUTER_PARAMS', this.lesParametres )

      this.$router.push({ name: "Edit", params: { id: immagine } })
      return false;
    }
  },
};
</script>

<style>
.flex { display: flex; }
.flex-end { justify-content: flex-end }

#collegio .p-card, #collegio .p-card-body { border-radius: 10px !important; }
#collegio .p-card-body { background-color: #6c8cbf; /* #c9c9ff; */ }
#collegio .p-card .p-card-body { padding: 4px 7px }
#collegio .p-card { margin: 5px; text-align: left; }
#collegio .p-card-content { color: #fffa8f; font-size: 16px; }
#collegio .list-center a { color: #fffa8f; }
#collegio .p-card-content { text-align: left !important; padding: 0px !important; }
#collegio .memberLabels { min-width: 140px; }

#collegio .submenu { 
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background-color: #025563ba;
  color: white;
  padding: 3px 9px;
  border-radius: 9px;
}
#collegio .submenuIcon { 
  cursor: pointer;
  margin-left: 5px;
  max-width: 30px;
}

</style>