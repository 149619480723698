import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Annuario from "@/components/Annuario.vue";
import Collegio from "@/components/Collegio.vue";
import Giubileo from "@/components/Giubileo.vue";
import SchedaFrate from "@/components/SchedaFrate.vue";
import SchedaAggiunto from "@/components/SchedaAggiunto.vue";
import OrarioGiornaliero from "@/components/OrarioGiornaliero.vue";
import Edit from "@/components/Edit.vue";
import Suore from "@/components/Suore.vue";
import SchedaSuora from "@/components/SchedaSuora.vue";
import Penitenzieria from "@/components/Penitenzieria.vue";
import Vaticano from "@/components/Vaticano.vue";
import Conventuali from "@/components/Conventuali.vue";
import Altro from "@/components/Altro.vue";
import AltroCategoria from "@/components/AltroCategoria.vue";
import Admin from "@/components/Admin.vue";
import Viewer from "@/components/Viewer.vue";
import Accoglienza from "@/components/Accoglienza.vue";
import AccoglienzaSostitutiOspiti from "@/components/AccoglienzaSostitutiOspiti.vue";
import AccoglienzaVacanze from "@/components/AccoglienzaVacanze.vue";
import AccoglienzaPartenzeArrivi from "@/components/AccoglienzaPartenzeArrivi.vue";
import Password from "@/components/Password.vue";
import NotFound from "@/components/NotFound.vue";

const routes = [
    {
        path: "/",
        name: "home-page",
        component: Home,
        // on déroute sur /annuario lorsque la home page est appelée (au démarrage ou refresh d'une page) sinon l'on vient d'une autre page et dans ce cas on va de l'avant avec l'affichage de la home
        beforeEnter(to, from, next){
            if ( from.path == '/' ) { next('/annuario') }
            else { next() }
        }
    },

    /* ANNUARIO */
    {
        path: "/annuario",
        name: "Annuario",
        component: Annuario,
    },
    {
        path: "/annuario/collegio",
        name: "Collegio",
        component: Collegio,
    },
    {
        path: "/annuario/collegio/:id",
        name: "SchedaFrate",
        component: SchedaFrate,
    },
    {
        path: "/annuario/giubileo",
        name: "Giubileo",
        component: Giubileo
    },
    {
        path: "/annuario/giubileo/:id/edit",
        name: "Edit",
        component: Edit,
    },
    {
        path: "/annuario/giubileo/:id",
        name: "SchedaAggiunto",
        component: SchedaAggiunto,
    },
    {
        path: "/annuario/suore",
        name: "Suore",
        component: Suore,
    },
    {
        path: "/annuario/suore/:id",
        name: "SchedaSuora",
        component: SchedaSuora,
    },
    {
        path: "/annuario/penitenzieria",
        name: "Penitenzieria",
        component: Penitenzieria,
    },
    {
        path: "/annuario/vaticano",
        name: "Vaticano",
        component: Vaticano,
    },
    {
        path: "/annuario/conventuali",
        name: "Conventuali",
        component: Conventuali,
    },
    {
        path: "/annuario/orariogiornaliero",
        name: "OrarioGiornaliero",
        component: OrarioGiornaliero,
    },

    /* ALTRO */
    {
        path: "/altro",
        name: "Altro",
        component: Altro,
    },
    {
        path: "/altro/:id",
        name: "altroCategoria",
        component: AltroCategoria,
    },

    /* ACCOGLIENZA - SOSTITUTI - OSPITI */
    {
        path: "/accoglienza",
        name: "Accoglienza",
        component: Accoglienza,
    },
    {
        path: "/accoglienza/sostituti_ospiti",
        name: "AccoglienzaSostitutiOspiti",
        component: AccoglienzaSostitutiOspiti,
    },
    {
        path: "/accoglienza/vacanze",
        name: "AccoglienzaVacanze",
        component: AccoglienzaVacanze,
    },
    {
        path: "/accoglienza/partenze_arrivi",
        name: "AccoglienzaPartenzeArrivi",
        component: AccoglienzaPartenzeArrivi,
    },

    /* ADMIN */
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        beforeEnter(to, from, next){
            if ( localStorage.getItem('user-password') && localStorage.getItem('user-level') == 100 ) next()
            else next('/notfound')
        }
    },

    /* DIVERS */
    {
        path: "/viewer/:linkID",
        name: "viewer",
        component: Viewer,
    },
    {
        path: "/password",
        name: "password",
        component: Password,
    },
    {
        path: "/notfound",
        component: NotFound,
    },
//    {
//        path: '/:catchAll(.*)', redirect: '/notfound',
//    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    console.log("router click to = ", to)
    console.log("router click from = ", from)
    if (to.name == "Giubileo" ){
        console.log('nome = giubileo')
    }
    if (to.meta.requiresAuth) {
        if ( !localStorage.getItem('user-password') ) next('/password');
        else next();
    } else next();
})

export default router;
