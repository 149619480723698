<template>
  <div id="orario" v-if="this.membersOK  && parametersOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/">Annuario</router-link>
    </div>
    <div class="titre-principal centre" style="padding-bottom: 20px">
      Orario Giornaliero
    </div>
    <div id="joursDeLaSemaine">
      <div class="jourLink" @click="goToAnchor('Lun', 'smooth')">Lun</div>&nbsp;
      <div class="jourLink" @click="goToAnchor('Mar', 'smooth')">Mar</div>&nbsp;
      <div class="jourLink" @click="goToAnchor('Mer', 'smooth')">Mer</div>&nbsp;
      <div class="jourLink" @click="goToAnchor('Gio', 'smooth')">Gio</div>&nbsp;
      <div class="jourLink" @click="goToAnchor('Ven', 'smooth')">Ven</div>&nbsp;
      <div class="jourLink" @click="goToAnchor('Sab', 'smooth')">Sab</div>&nbsp;
      <div class="jourLink" @click="goToAnchor('Dom', 'smooth')">Dom</div>
    </div>
    <div style="margin: 0 5px 80px 5px;">
      <template 
        v-for="(member, index) in this.membersTab" 
          :key=index
          >
          <!-- Titolo del Giorno / Link "Vai Su" -->
          <div :style="apremBreak(member.horaire)" v-if="member.horaire != '-----------'">
            <div v-if="jourBreak(member.giorno)">
              <div :id="setID(joursSemLibelle[member.giorno])" class="flex flex-space-between scrollMargin">
                <div class="jourLibelle">{{ joursSemLibelle[member.giorno] }}</div>
                <div class="vaiSu bordeaux" @click="goToAnchor('orario', 'instant')">▲ vai su</div>
              </div>
            </div>
            <!-- Linea singola -->
            <div class="ligneContainer">
              <div class="ligne-left flex">
                <div class="flex flex-align-center ligne-left" v-html="horaireBreak(member.horaire)"></div>

                <!-- Nome penitenziere -->
                <div class="ligne-left">
                  <div class="nomePenitenziere flex">
                    <div :class="[ { bordeaux: member.tipo == 'agg'} ]"><span>{{ member.nome }} {{ member.cognome }}</span></div>
                    <div class="langues">&nbsp;({{ member.lingue }})</div>
                  </div>

                  <!-- Confessionale per aggiunti -->
                  <div class="flex" v-if="this.$store.state.params['confessionale'][member.conf]">
                    <div class="confessionale">Confessionale di</div>
                    <div class="confessore bleuFonce right">&nbsp;{{ this.$store.state.params['confessionale'][member.conf] }}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

var bJour = -1;
var bHoraire = "";
var horaire16Break = true;

export default {
  name: "orario-giornaliero",
  data() {
    return {
      membersTab: [],
      membersOK: false,
      joursSemaine: [ "Lu", "Ma", "Me", "Gi", "Ve", "Sa", "Do" ],
      joursSemLibelle: [ "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica" ],
      ligneNome: "",
      confessionaux: [],
      horaireFirst: "",
    }
  },
  computed: {
    ...mapState(["annuarioDB", "annuarioDBOpen", 'params', 'parametersOpen', "routerParams"]),
  },
  created() { 
    if (!this.parametersOpen) {
      console.log('parametres not open')
      var x = this.$store.dispatch("getParams")
      x.then( () => {
        console.log('params après x = ', this.$store.state.params )
        console.log('confessionnaux att = ', this.$store.state.params['confessionale']['att']) 
      }) 
    } else { console.log('parametres déjà chargés')}

    console.log('debut chargeDatabase')
    if (!this.annuarioDBOpen) {
      console.log('annuariodb not chargé ', this.annuarioDB)
      this.$store.dispatch("getAnnuario") } 
    else { 
      this.createMembersTab() 
    }
  },
  watch: {
    annuarioDB: function() {

      this.createMembersTab()

    }
  },

  mounted() {
    this.$store.dispatch("setHighlight", { on: true }); // highlight the tab of tabMenu
  },

  unmounted() {
    this.$store.dispatch("setHighlight", { on: false }); // highlight the tab of tabMenu
  },

  methods: {

    setID(giorno) {
      return giorno.substring(0, 3)
    },

    apremBreak(horaire) {
      if ( horaire.substr(0, 2) == "16" ) {
        if ( horaire16Break ) {
          horaire16Break = false
          return "margin-top: 15px" 
        }
      }
      else   {
        horaire16Break = true
        return "margin-top: 0px"
      }
    },

    jourBreak(giorno) {
      if ( giorno != bJour ) {
        bJour = giorno 
        return true }
      else {
        return false
      }
    },

    horaireBreak(horaire) {
      if ( horaire != bHoraire ) {
        bHoraire = horaire
        let varToReturn = "<span class='horaireBackground ligneBreak'>" + horaire + "</span>"
        return varToReturn; }
      else {
        let varToReturn = "<span class='ligneNonBreak'></span>"
        return varToReturn; 
      }
    },

    goToAnchor(leJour, comportement) {
      console.log(leJour)
      let x = document.getElementById(leJour);
      x.scrollIntoView({ behavior: comportement, block: "start" });
    },

    createMembersTab() {
      console.log('boucle watch', this.annuarioDB);
      var dateDuJour = new Date()

      for (let membro of this.annuarioDB[1]) {
        // différence date entre date du jour et date d'arrivée.
        if ( membro.data_arrivo == '9999-99-99' ) { membro.data_arrivo = '2025-12-31'}
        console.log('date arrivo = ', membro.data_arrivo)
        const d1 = new Date(dateDuJour);
        const d2 = new Date(membro.data_arrivo);      
        // Calculer la différence en millisecondes
        const differenceMillis = d2 - d1;      
        // Convertir la différence en jours
        var differenceJours = Math.trunc(differenceMillis / (1000 * 3600 * 24))

        console.log('difference = ', differenceJours)

        console.log('nome e differenza = ', membro.nome, differenceJours)
        if ( membro.data_arrivo != '9999-99-99' && differenceJours < 8 && membro.attivo == 1 ) {
          let lignes = membro.orari.split('\r\n')

          for (let ligne of lignes) {
            let matinSoir = ligne.split('/')
            let leMatin = matinSoir[0].split(':')
            let jour = this.joursSemaine.indexOf( leMatin[0] )
            let horaireMatinConfessional = leMatin[1].split('#')
            let horaireMatin = horaireMatinConfessional[0].trim()
            let horaireMatinConf = horaireMatinConfessional[1] ? horaireMatinConfessional[1].trim() : ""

            let member = {
              nome: membro.nome,
              cognome: membro.cognome,
              tipo: membro.tipo,
              lingue: membro.lingue,
              giorno: jour, 
              horaire: horaireMatin, 
              conf: horaireMatinConf,
            }
            this.membersTab.push(member)

            let horaireSoirConfessional = matinSoir[1].split('#')
            let horaireSoir = horaireSoirConfessional[0].trim()
            let horaireSoirConf = horaireSoirConfessional[1] ? horaireSoirConfessional[1].trim() : ""
            
            member = {  
              nome: membro.nome,
              cognome: membro.cognome,
              tipo: membro.tipo,
              lingue: membro.lingue,
              giorno: jour, 
              horaire: horaireSoir, 
              conf: horaireSoirConf,
            } 
            this.membersTab.push(member)
          }
        }
        console.log('membertabs = ', this.membersTab)

      }
      // trier
      console.log("membertab avant tri = ", this.membersTab )
      this.membersTab.sort((a, b) => {
        // Comparer par jour de la semaine
        if (a.giorno < b.giorno) return -1;
        if (a.giorno > b.giorno) return 1;

        // Si les jours de la semaine sont identiques, comparer par horaire
        if (a.horaire < b.horaire) return -1;
        if (a.horaire > b.horaire) return 1;

        // Si les jours de la semaine et horaire sont identiques, comparer par tipo (descendant)
        if (a.tipo < b.tipo) return 1;
        if (a.tipo > b.tipo) return -1;

        // et enfin par nom
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;

        // Si tout est identique
        return 0;
      });
      console.log('membersTab à la fin = ', this.membersTab)
      this.membersOK = true;
      console.log('memberOK = ', this.membersOK)
    }

  }
}

</script>

<style>
.horaireBackground { 
  padding: 4px;
  font-weight: bold;
	border: 1px solid #b8b8ff;  
	max-height: 23px;
	background-color: azure;
	border-radius: 10px; }
.jourLibelle, .vaiSu { text-align: left; 
  cursor: pointer;
  font-size: 16px; 
  font-weight: bold; 
  margin-top: 20px;
  padding: 0 5px; 
  scroll-margin-top: 5rem; }
.ligneContainer { 
  display: flex; 
  justify-content: space-between;
  align-items: center;
  background-color: beige;
  border-bottom: 1px solid #a52a2a30;
  padding: 0 10px;
  border-radius: 7px;
  min-height: 25px; }
.ligne-left, .ligne-right {   
  text-align: left; 
  min-width: 77px;
  font-size: 12px;  }
.ligneBreak { display: block; min-width: 75px; }
.ligneNonBreak { margin-left: 75px; max-width: 75px; }
.ligneBreak span, .ligneNonBreak span { font-weight: bold; }
.ligneHoraire { margin-top: 5px; }
.nomePenitenziere { font-weight: bold; margin-left: 8px; padding: 5px 0; }
.nomePenitenziere span { margin-left: 3px;  }
.confessionale { margin: -5px 0 0 10px; }
.confessore { margin-top: -5px; padding-bottom: 5px; }
.m0 { margin: 0px; }
.pt10 { padding-top: 10px; }
.scrollMargin { scroll-margin-top: 2rem; }
.langues { font-size: 11px; font-weight: 400!important }
.bleuFonce { color: blue; font-weight: bold; }

#joursDeLaSemaine {
  position: sticky;
  top: 0;
  display: flex; 
  justify-content: space-evenly;
  padding: 5px 10px;
  background-color: white;
}
.jourLink { 
  cursor: pointer;
  padding: 3px;
  background-color: rgb(123, 0, 0);
  border-radius: 20px;
  color: white; 
  width: 50px;
 }

</style>