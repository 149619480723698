<template>
  <div id="scheda" class="centre" v-if="this.annuarioDBOpen && this.parametersOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/giubileo/">
        Confessori Aggiunti Giubileo
      </router-link>
    </div>
    <Card style="margin: 10px">
      <template #title>
        <div class="p-card-name">
          {{ this.frate.cognome.toUpperCase() }}
          {{ this.frate.nome }}
        </div>
      </template>
      <template #content>
        <div class="background-2"> 
          <!-- Giurisdizione -->
          <div><strong>{{ this.frate.giurisdizione }}</strong></div>
          <!-- Arrivée -->
          <div style="display: block; margin-top: 5px;">
            <div style="width: 65px; display: inline-block;"><strong>Arrivo:</strong></div>
            <div style="display: inline-block" v-if="this.frate.data_arrivo != '9999-99-99'">
              {{ dataFormat(this.frate.data_arrivo) }}
            </div>
          </div>
          <div style="display: block; margin-left: 65px;" v-if="this.frate.info_arrivo != ''">
            <div style="display: inline-block" v-html="this.frate.info_arrivo"></div>
          </div>
          <!-- Départ -->
          <div style="display: block;">
            <div style="width: 65px; display: inline-block;">
              <strong>Partenza:</strong>
            </div>
            <div style="display: inline-block" v-if="this.frate.data_partenza != '9999-99-99'">
              {{ dataFormat(this.frate.data_partenza) }}
            </div>
          </div>
          <div style="display: block; margin-left: 65px;" v-if="this.frate.info_partenza != ''">
            <div style="display: inline-block" v-html="this.frate.info_partenza"></div>
          </div>
        </div>


        <!-- Orario Confessioni -->
        <div class="centre orario">Orario Confessioni</div>
        <div class="background-1">

          <div v-html="horaireIndividuel(this.frate.orari)"></div>

        </div>


        <div class="background-2" style="margin-top: 30px;">
          <!-- Data Nascita e Onomastico -->
          <div class="flex flex-wrap">
            <div style="min-width: 125px;">
              <strong>Compleanno:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.nascita) }} ({{
                calcoloAnni(this.frate.nascita) }}
              anni)
            </div>
          </div>
          <div class="flex">
            <div style="min-width: 125px;">
              <strong>Onomastico:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.onomastico) }}
            </div>
          </div>
        </div>


        <div class="background-1"> 
          <!-- Luogo di abitazione -->
          <div class="flex">
            <div style="min-width: 125px;">
              <strong>Luogo abitazione:&nbsp;</strong>
            </div>
            <template 
              v-for="(luogo, index) in this.$store.state.params['luogo']" 
                :key="index" 
                >
                <div v-if="this.frate.luogo==index">{{ luogo }}</div>
            </template>
          </div>
          <!-- Stanza + piano -->
          <div class="flex">
            <div style="min-width: 125px;">
              <strong>Stanza:</strong>
            </div>
            <div class="memberData" v-if="this.frate.stanza != ''">{{ this.frate.stanza }}</div>
          </div>
        </div>

        <!-- Email et Cellulare -->
        <div class="background-2">
          <div class="flex">
            <div style="min-width: 125px;">
              <strong>E-mail:</strong>
            </div>
            <div class="memberMail">
              <a :href="'mailto:' + this.frate.email">{{ this.frate.email }}</a>
            </div>
          </div>
          <div class="flex">
            <div style="min-width: 125px;">
              <strong>Tel. Cellulare:</strong>
            </div>
            <div class="memberData">{{ this.frate.cellulare }}</div>
          </div>
        </div>

        <!-- Professione e Ordinazione -->
        <div class="background-1">
          <div class="flex">
            <div style="min-width: 125px;">
              <strong>Professione:</strong>
            </div>
            <div class="memberData" v-if="this.frate.professione != ''">
              {{ dataFormat(this.frate.professione) }}
            </div>
          </div>
          <div class="flex">
            <div style="min-width: 125px;">
              <strong>Ordinazione:</strong>
            </div>
            <div class="memberData" v-if="this.frate.ordinazione != ''">
              {{ dataFormat(this.frate.ordinazione) }}
            </div>
          </div>
        </div>

        <!-- Osservazioni -->
        <div class="background-2">
          <div><strong>Osservazioni</strong></div>
          <textarea rows="7" style="width: 100%;" class="list-left"
                v-model="this.frate.remarks">
          </textarea>
        </div>

        <button v-if="this.editable" type="button" @click="editMember(this.frate.immagine)">Edit</button>

      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "scheda-aggiunto",
  components: { Card },
  data() {
    return {
      afficheScheda: false,
      frate: {},
      nofrate: null,
      joursSemaine: [ "Lu", "Ma", "Me", "Gi", "Ve", "Sa", "Do" ],
      joursSemLibelle: [ "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica" ],
      mesi: [ "gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre", ],
      giorni: { lun: "Lunedì", mar: "Martedì", mer: "Mercoledì", gio: "Giovedì", ven: "Venerdì", sab: "Sabato", dom: "Domenica", },
      editable: false,
    };
  },
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen', 'params', 'parametersOpen'])
  },
  created() {
    console.log('enter created')
    if (!this.parametersOpen) {
      console.log('not parametersOpen') 
      var x = this.$store.dispatch("getParams")
      x.then( () => {
        console.log('params après x = ', this.$store.state.params )
        console.log('confessionnaux att = ', this.$store.state.params['confessionale']['att']) 
      }) 
    } else { console.log('parametres déjà chargés')}

    if ( !this.annuarioDBOpen ) {
      this.$store.dispatch("getAnnuario"); }
    else {

      var cpt = 0;
      console.log('Frati avant boucle normal', this.annuarioDB);
      // recherche le frère dans la liste des membres du collège
      for (let membro of this.annuarioDB[1]) {
        if (membro.immagine == this.$route.params.id) {
          break;
        }
        cpt++;
      }
      this.nofrate = parseInt(cpt);
      this.frate = this.annuarioDB[1][this.nofrate];
    }

    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page

    // Set the user level
    var theUserLevel = localStorage.getItem('user-level');
    console.log('user-level = ', theUserLevel); 
    this.editable = theUserLevel == "100" ? true : false; 

  },
  watch: {
    annuarioDB: function() {
      var cpt = 0;
      console.log('Frati avant boucle watch', this.annuarioDB);
      for (let membro of this.annuarioDB[1]) {
        if (membro.immagine == this.$route.params.id) {
          break;
        }
        cpt++;
      }
      this.nofrate = parseInt(cpt);
      this.frate = this.annuarioDB[1][this.nofrate];
    }
  },
  methods: {
    horaireIndividuel(orario) {
      console.log('orario = ', orario)
      const horaireTab = []

      let lignes = orario.split('\r\n')

      for (let ligne of lignes) {
        let matinSoir = ligne.split('/')
        let leMatin = matinSoir[0].split(':')
        let jour = this.joursSemaine.indexOf( leMatin[0] )
        let horaireMatinConfessional = leMatin[1].split('#')
        let horaireMatin = horaireMatinConfessional[0].trim()
        let horaireMatinConf = horaireMatinConfessional[1] ? horaireMatinConfessional[1].trim() : ""

        let horaireSoirConfessional = matinSoir[1].split('#')
        let horaireSoir = horaireSoirConfessional[0].trim()
        let horaireSoirConf = horaireSoirConfessional[1] ? horaireSoirConfessional[1].trim() : ""

        let member = {
          giorno: jour, 
          horaireDuMatin: horaireMatin, 
          confessionalDuMatin: horaireMatinConf,
          horaireDuSoir: horaireSoir, 
          confessionalDuSoir: horaireSoirConf,
        }

        horaireTab.push(member)

      }

      var codeHTML = ""
      horaireTab.forEach( (el) => {
        codeHTML += `<div class="jour">${this.joursSemLibelle[el.giorno]}</div>`
        codeHTML += `<div class="flex">`
        let conf = el.horaireDuMatin != '-----------' ? `<strong>Confessionale</strong> di <span class="bleuFonce">${this.$store.state.params['confessionale'][el.confessionalDuMatin]}` : "";
        codeHTML += `   <div><span class="bleuFonce">${el.horaireDuMatin}</span> ${conf}</div>`
        codeHTML += `</div>`
        codeHTML += `<div class="flex">` 
        conf = el.horaireDuSoir != '-----------' ? `<strong>Confessionale</strong> di <span class="bleuFonce">${this.$store.state.params['confessionale'][el.confessionalDuSoir]}</span>` : "";
        codeHTML += `   <div><span class="bleuFonce">${el.horaireDuSoir}</span> ${conf}</div>`
        codeHTML += `</div>` 
      })
      return codeHTML
    },

    dataFormat(laData) {
      var dN = laData.split("-");
      console.log('ladata length = ', laData.length, " ", laData)
      if (laData.length == 0) {
        var annoDisp = "";
        var giornoDisp = ""; }
      else if (laData.length > 5) { // la date = aaaa.mm.jj
        annoDisp = dN[0];
        giornoDisp = dN[2] } 
      else {                  // la date = jj.mm
        annoDisp = "";
        giornoDisp = dN[0];
      }
      if ( !dN[1] ) { var meseDisp = "" }
      else { 
        console.log('dn1 = ', dN[1])
        meseDisp = this.mesi[parseInt(dN[1]) - 1]
        console.log('giornodisp e mesedisp : ', giornoDisp, " ", meseDisp)
      }

      return ( giornoDisp + " " + meseDisp + " " + annoDisp );
    },

    calcoloAnni(birthday) {
      var bd = birthday.split("-");
      var newBirthday = new Date(
        parseInt(bd[0]),
        parseInt(bd[1]) - 1,
        parseInt(bd[2])
      );
      var ageDifMs = Date.now() - newBirthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    editMember(immagine) {
      this.lesParametres = { 
        urlBack: '/annuario/giubileo',
        libelleBack: 'Giubileo Confessori Aggiunti Giubileo',
        noTab: this.nofrate
      }
      this.$store.commit( 'SET_ROUTER_PARAMS', this.lesParametres )

      this.$router.push({ name: "Edit", params: { id: immagine } })
      return false;
    },
  },
  mounted() {
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
};
</script>

<style>
button { margin: 10px 0 !important }

.orari { width: 100%; font-family: monospace; font-size: 12px; }
.flex { display: flex; }
.flex-wrap { flex-wrap: wrap; }

#scheda .p-card-body { padding-top: 0px !important; }
#scheda .p-card .p-card-content { padding: 0; }
#scheda .p-card-content { text-align: left !important; }
.p-card .p-card-title { font-size: 18px; }
#scheda .memberLabels { min-width: 140px; }
#scheda .p-card-name { background-color: #6c8cbf; color: #fffa8f; padding: 10px; border-radius: 10px; }
#scheda .background-1,
#scheda .background-2 { border-radius: 10px; padding: 10px; margin-bottom: 0px; }
#scheda .background-1 { background-color: #fffdd3; border: 1px solid rgb(255, 230, 0); }
#scheda .background-2 { background-color: white; border-left: 1px solid rgb(255, 230, 0); border-right: 1px solid rgb(255, 230, 0); }
#scheda .border-bottom { border-bottom: 1px solid rgb(255, 230, 0); }
#scheda .memberMail a { text-decoration: underline; }
.inlineBlock { display: inline-block; }
.orario { font-size: 20px; font-weight: bold; margin: 0 0 10px; }
.giorno { min-width: 100px; }
.jour { 
  font-size: 16px; 
  font-weight: bold; 
  margin-top: 10px;
  margin-bottom: 3px;    
  width: fit-content;
  padding: 5px;
  background-color: aliceblue;
  border: 1px solid #0000ff42;
  border-radius: 5px;
  line-height: 0.8; 
}
.jour:first-child { margin-top: 0; }
.nomeConfessore { color: blue; font-weight: bold; }
.horaireJour{ display: inline-block; }
.matinPomeriggio { min-width: 100px; }
.mattino, .pomeriggio { min-width: 45px; font-weight: bold; }
.mattino-titolo { margin: 0 0 5px 83px; min-width: 100px; }
</style>