<template>
    <div id="password">
      <keep-alive>
        <img src="@/assets/img/logo_penitenzieri.png" width="200" />
      </keep-alive>
      <div class="title-admin">ACCESSO APP</div>
      <div style="text-align: center; margin-bottom: 80px;" >
          <h2>Introdurre la Password</h2>
          <div style="text-align: center;">
              <Password ref="passRef" @keydown="clearError" @keyup.enter="verifyLogin" v-model="password" :feedback="false" toggleMask autofocus />  
              <Button @click="verifyLogin" label="Accedi" />
          </div>
          <div class="error">{{ this.$store.state.errorMessage }}</div>
      </div>
   </div>
</template>

<script>
import Password from 'primevue/password';
import Button from 'primevue/button';
import 'primeflex/primeflex.css';

export default {
  name: "password-area",
  components: { Password, Button },
  data () {
    return {
      password: null,
      notLogged: true,
      error: "",
    }
  },
//  mounted() {
//    this.$refs.passRef.focus();
//  },
  methods: {
    verifyLogin () {
      this.$store.commit( 'SET_LOGIN_PASS', this.password );
      this.$store.dispatch('loginVerify');
    },
    clearError() {
        this.$store.state.errorMessage = "";
    }
  }
}
</script>

<style>
#password img { margin: 40px 0; }
.error {
  color: red !important;
  width: 306px;
  margin: 0 auto;
  text-align: left;
}
.pass {
    text-align: center;
    width: 400px;
}
.button {
    text-align: center;
}
</style>