<template>
  <div id="home">
    <keep-alive>
      <img src="@/assets/img/logo_penitenzieri.png" width="200" />
    </keep-alive>
    <div class="menu" style="margin-bottom: 80px">
      <div>
        <router-link to="/annuario">
          Annuario Penitenzieri
        </router-link>
      </div>
      <div>
        <router-link to="/annuario/giubileo">
          Giubileo
        </router-link>
      </div>
      <div>
        <router-link to="/altro">
          Altre attività
        </router-link>
      </div>
      <div>
        <router-link to="/accoglienza">
          Accoglienza
        </router-link>
      </div>
      <div v-if="whatLoginLevel == 100">
        <router-link to="/admin">
          Admin
        </router-link>
      </div>
      <div @click="logout()">
        <router-link to="/password">
          Disconnettersi
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: "home-page",
  created() {
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  methods: {
    logout() {
      localStorage.removeItem('user-password');
      localStorage.removeItem('user-level');
    }
  },
  computed:{ 
    whatLoginLevel(){
//      return this.$store.state.loginLevel;
      return localStorage.getItem('user-level');
    } 
  }
};
</script>