<template>
  <div id="scheda" class="centre" v-if="this.annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/collegio/">
        Collegio dei Penitenzieri Vaticani
      </router-link>
    </div>
    <Card style="margin: 10px">
      <template #title>
        <div class="p-card-name">
          {{ this.frate.cognome.toUpperCase() }}
          {{ this.frate.nome }}
        </div>
      </template>
      <template #content>
        <div class="memberCardImg">
          <!--      <img :src="srcImg(membro.immagine)" /> -->
        </div>

        <div class="giurisdizione">
          <!-- Giurisdizione -->
          <div class="memberGiurisdizione">
            <strong>{{ this.frate.giurisdizione }}</strong>
          </div>
          <!-- Sito web -->
          <div class="memberSito" style="margin-bottom: 15px">
            <a
              :href="this.frate.sitoweb"
              @click="window.open(this.frate.sitoweb, '_blank')"
              target="new"
              >{{ this.frate.sitoweb }}</a
            >
          </div>
        </div>
        <div class="background-1">
          <!-- Telefono interno -->
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. interno:</strong>
            </div>
            <div class="memberData">{{ this.frate.tel }}</div>
          </div>
          <!-- Stanza + piano -->
          <div class="flex">
            <div class="memberLabels">
              <strong>Stanza:</strong>
            </div>
            <div class="memberData">{{ this.frate.stanza }}</div>
            <div class="memberLabels">
              &nbsp;({{ this.frate.piano }}° Piano)
            </div>
          </div>
        </div>
        <div class="background-2">
          <!-- Data nascita -->
          <div class="flex">
            <div class="memberLabels">
              <strong>Compleanno:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.nascita) }} ({{
                calcoloAnni(this.frate.nascita)
              }}
              anni)
            </div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Onomastico:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.onomastico) }}
            </div>
          </div>
        </div>
        <div class="background-1">
          <div class="flex">
            <div class="memberLabels">
              <strong>Professione:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.professione) }}
            </div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Ordinazione:</strong>
            </div>
            <div class="memberData">
              {{ dataFormat(this.frate.ordinazione) }}
            </div>
          </div>
        </div>
        <div class="background-2">
          <div class="flex">
            <div class="memberLabels">
              <strong>E-mail:</strong>
            </div>
            <div class="memberMail">
              <a :href="mailaddr(this.frate.email)">{{ this.frate.email }}</a>
            </div>
          </div>
        </div>
        <div class="background-1">
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. Cellulare:</strong>
            </div>
            <div class="memberData">{{ this.frate.cellulare }}</div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. Famiglia:</strong>
            </div>
            <div class="memberData">{{ this.frate.telfamiglia }}</div>
          </div>
        </div>
        <div class="background-2 border-bottom">
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. all'Estero:</strong>
            </div>
            <div class="memberData">{{ this.frate.telestero }}</div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. Provincia:</strong>
            </div>
            <div class="memberData">{{ this.frate.telprovincia }}</div>
          </div>
        </div>
        <div class="centre orario">Orario Confessioni</div>
        <div class="background-1">
          <div class="flex">
            <div class="mattino-titolo"><strong>Mattino</strong></div>
            <div><strong>Pomeriggio</strong></div>
          </div>
          <textarea rows="7" style="width: 100%; font-family: monospace" class="list-left orari"
                v-model="this.frate.orari">
          </textarea>
        </div>
        <div class="background-2">
          <div><strong>Osservazioni</strong></div>
          <textarea rows="7" style="width: 100%;" class="list-left"
                v-model="this.frate.remarks">
          </textarea>
        </div>

        <button v-if="this.editable" type="button" @click="editMember(this.frate.immagine)">Edit</button>

      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "scheda-frate",
  components: { Card },
  data() {
    return {
      afficheScheda: false,
      frate: {},
      mesi: [ "gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre" ],
      giorni: { lun: "Lunedì", mar: "Martedì", mer: "Mercoledì", gio: "Giovedì", ven: "Venerdì", sab: "Sabato", dom: "Domenica" },
      editable: false,
      nofrate: null,  
    };
  },
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen'])
  },
  created() {
    console.log('enter created')
    if ( !this.annuarioDBOpen ) {
      console.log('Annuario.vue mounted');
      this.$store.dispatch("getAnnuario"); 
    }
    else {
      var cpt = 0;
      console.log('Frati avant boucle', this.annuarioDB);
      for (let membro of this.annuarioDB[1]) {
        if (membro.immagine == this.$route.params.id) {
          break;
        }
        cpt++;
      }

      this.nofrate = parseInt(cpt);
      this.frate = this.annuarioDB[1][parseInt(cpt)];
      console.log('this.frate = ', this.frate )
    }
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page

    // Set the user level
    var theUserLevel = localStorage.getItem('user-level');
    console.log('user-level = ', theUserLevel); 
    this.editable = theUserLevel == "100" ? true : false;     
  },
  watch: {
    annuarioDB: function() {
      var cpt = 0;
      console.log('Frati avant boucle', this.annuarioDB);
      for (let membro of this.annuarioDB[1]) {
        if (membro.immagine == this.$route.params.id) {
          break;
        }
        cpt++;
      }
      this.nofrate = parseInt(cpt);
      this.frate = this.annuarioDB[1][parseInt(cpt)];
      console.log('this.frate = ', this.frate )
    }
  },
  methods: {
    mailaddr(mail) {
      return "mailto:" + mail;
    },

    dataFormat(laData) {
      var dN = laData.split("-");
      console.log('ladata length = ', laData.length, " ", laData)
      if (laData.length > 5) { // la date = aaaa.mm.jj
        var annoDisp = dN[0];
        var giornoDisp = dN[2] } 
      else {                  // la date = jj.mm
        annoDisp = "";
        giornoDisp = dN[0];
      }
      var meseDisp = this.mesi[parseInt(dN[1]) - 1]
      console.log('giornodisp e mesedisp : ', giornoDisp, " ", meseDisp)
      return ( giornoDisp + " " + meseDisp + " " + annoDisp );
    },

    calcoloAnni(birthday) {
      var bd = birthday.split("-");
      var newBirthday = new Date(
        parseInt(bd[0]),
        parseInt(bd[1]) - 1,
        parseInt(bd[2])
      );
      var ageDifMs = Date.now() - newBirthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    editMember(immagine) {
      this.lesParametres = { 
        urlBack: '/annuario/collegio',
        libelleBack: 'Collegio dei Penitenzieri Vaticani',
        noTab: this.nofrate
      }
      this.$store.commit( 'SET_ROUTER_PARAMS', this.lesParametres )

      this.$router.push({ name: "Edit", params: { id: immagine } })
      return false;
    },

  },
  mounted() {
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
};
</script>

<style>
button { margin: 10px 0 !important }

.orari { width: 100%; font-family: monospace; font-size: 12px; }

#scheda .p-card-body { padding-top: 0px !important; }
#scheda .p-card-content { text-align: left !important; }
#scheda .memberLabels { min-width: 100px; }
#scheda .p-card-name { background-color: #6c8cbf; color: #fffa8f; padding: 10px; border-radius: 10px; text-align: center; }
#scheda .background-1, #scheda .background-2 { border-radius: 10px; padding: 10px; margin-bottom: 0px; }
#scheda .background-1 { background-color: #fffdd3; border: 1px solid rgb(255, 230, 0); }
#scheda .background-2 { background-color: white; border-left: 1px solid rgb(255, 230, 0); border-right: 1px solid rgb(255, 230, 0); }
#scheda .border-bottom { border-bottom: 1px solid rgb(255, 230, 0); }
#scheda .memberMail a { text-decoration: underline; }
.orario { font-size: 20px; font-weight: bold; margin: 10px 0; }
.giorno { min-width: 100px; }
.mattino, .pomeriggio { min-width: 100px; }
.mattino-titolo { margin: 0 0 5px 100px; min-width: 100px; }
</style>